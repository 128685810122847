import React from "react";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
      <header>
        <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
            <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                <Link to="/" class="flex items-center">
                    <img src="https://ydn-media.s3.us-east-2.amazonaws.com/uploads/manual/logo-96.png" class="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
                    <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">YourDigitalNook</span>
                </Link>
                <div class="flex items-center lg:order-2">
                    <Link to="/contact-us" class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">Contact Us</Link>
                </div>
            </div>
        </nav>
      </header>
  );
};

export default Navbar;
