export const productEndpoints = {
    getProducts: '/product/products',
};

export const paypalEndPoints = {
    createOrder: '/payment/paypal/create-order',
    captureOrder: '/payment/paypal/capture-order',
}

export const contactEndPoints = {
    submitContact: '/contact/submit',
}