import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from "../../../config/config.js";
import { useCreateOrderMutation, useCaptureOrderMutation } from './paypalApiSlice.js';

const PayPalButton = ({ products }) => {

    const navigate = useNavigate();
    const [createOrder, { isLoading, isError }] = useCreateOrderMutation();
    const [captureOrder] = useCaptureOrderMutation();

    const initialOptions = {
        clientId: PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture",
    };

    const styles = {
        shape: "rect",
        layout: "vertical",
        label: "buynow",
        color: "silver",
    };

    const handleCreateOrder = async () => {
        try {
            const requestBody = { products }
            const response = await createOrder(requestBody).unwrap();
            return response.data.id
        } catch (error) {
            console.error('Failed to create order:', error.message);
        }
    };

    const handleOnApprove = async (data) => {
        const requestBody = { orderID: data.orderID };
        const response = await captureOrder(requestBody).unwrap();
        navigate(`/checkout/order-received/${response.data.id}`, { state: { response } });
    };

    return (
        <>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={styles}
                    createOrder={handleCreateOrder}
                    onApprove={handleOnApprove}
                />
            </PayPalScriptProvider>
        </>
    );
};

export default PayPalButton;
