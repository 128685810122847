import React from "react";
import { useLocation } from "react-router-dom";

const OrderReceived = () => {
  const location = useLocation();
  const { response } = location.state || {}; // Optional chaining in case no state is passed
  const data = response.data;
  const id = data.id;
  const email_address = data.payer.email_address;
  const payer_name = data.payer.name.given_name;
  return (
    <section class="bg-white py-8 antialiased dark:bg-gray-900 md:py-16">
      <div class="mx-auto max-w-2xl px-4 2xl:px-0">
        <h2 class="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl mb-2">
          Thanks {payer_name} for your order!
        </h2>
        <p class="text-gray-500 dark:text-gray-400 mb-6 md:mb-8">
          Your order <strong>#{id}</strong> is currently being processed and
          will be completed within 24 hours during our standard business hours.
          You will receive a confirmation at{" "}
          <strong>email: {email_address}</strong> once your order is processed.
          If you don't see the email in your inbox, <strong>please don't forget to check your spam folder and mark it as not spam if found there.</strong>          
          For any urgent inquiries or further assistance, please feel free to
          reach out to us at <strong>contact@yourdigitalnook.com</strong>
        </p>
      </div>
    </section>
  );
};

export default OrderReceived;
