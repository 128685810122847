import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './layouts/Layout.jsx';
import Home from './pages/Home.jsx';
import ProductDetails from './features/product/ProductDetails.jsx';
import OrderReceived from './pages/OrderReceived.jsx';
import ContactUs from './pages/ContactUs.jsx';
import NotFound from './pages/NotFound.jsx';
import { initGA, trackPageView } from './utils/googleAnalytics.js';
function App() {
  useEffect(() => {
      initGA(); // Initialize Google Analytics
  }, []);

  return (
    <BrowserRouter>
      <AnalyticsTracker>
          <Routes>
            {/* public routes */}
            <Route path='/' element={<Layout />}>
              <Route index element={<Home />} />
              <Route path='/product/:productId' element={<ProductDetails />} />
              <Route path='/checkout/order-received/:orderId' element={<OrderReceived />} />
              <Route path='/contact-us' element={<ContactUs />} />
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
      </AnalyticsTracker>
    </BrowserRouter>
  );
}

// AnalyticsTracker component
const AnalyticsTracker = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
      trackPageView(location.pathname); // Track page views
  }, [location]);
  return children;
};

export default App;
