import { configureStore } from "@reduxjs/toolkit";
import { productApiSlice } from "../features/product/productApiSlice.js";
import { paypalApiSlice } from "../features/payment/paypal/paypalApiSlice.js";
import { contactApiSlice } from "../features/contact/contactApiSlice.js";
const apiSlices = [
    productApiSlice,
    paypalApiSlice,
    contactApiSlice,
];

export const store = configureStore({
    reducer: {
        ...Object.fromEntries(apiSlices.map(slice => [slice.reducerPath, slice.reducer])),
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...apiSlices.map(slice => slice.middleware)),
    devTools: true,
});
