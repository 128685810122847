import { createApi } from '@reduxjs/toolkit/query/react';
import { publicBaseQuery } from '../../app/api/apiSlice.js';
import { contactEndPoints } from '../../app/api/endpoints.js';

export const contactApiSlice = createApi({
  reducerPath: 'contactApi',
  baseQuery: publicBaseQuery,
  endpoints: builder => ({
    submitContact: builder.mutation({
      query: (requestBody) => ({
        url: contactEndPoints.submitContact,
        method: 'POST',
        body: requestBody,
      }),
    }),
  }),
});

export const { useSubmitContactMutation } = contactApiSlice;
