import ReactGA from "react-ga4";
import { GA_MEASUREMENT_ID } from "../config/config";

export const initGA = () => {
    ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const trackPageView = (path) => {
    ReactGA.send({ hitType: "pageview", page: path });
};

export const trackEvent = (category, action, label) => {
    ReactGA.event({ category, action, label });
};
